import { openMsgModal } from '@/common/Common.js'
import { MSG_TYPE } from '@/common/const.js'
import NoticeService from './NoticeService'

export function init(obj) {
  obj.noticeCount = 0
  obj.tableItems = []
  obj.$store.dispatch('init/setServiceLoading')
  NoticeService.getAll()
    .then(
      (response) => {
        obj.tableItems = response.data
        obj.noticeCount = obj.tableItems.length
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else if (e.response.status == 404) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
        } else {
          msg = e.response.data.message
        }
        console.log('msg=>' + msg)
        //openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

/**
 * 閉じる時、APIのUpdate処理実行
 * @param {*} obj
 */
export function afterCloseUpdate(obj) {
  obj.$store.dispatch('init/setServiceLoading')
  NoticeService.update(obj.tableItems)
    .then(
      () => {},
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}
