<template>
  <BaseModal
    dataClass="notice-modal"
    size="md"
    :title="detailInfo.title.label"
    v-bind="$attrs"
    v-on="$listeners"
    @show-modal="init(obj)"
  >
    <template v-slot:main>
      <div id="messageBox">
        <ul>
          <li v-for="item in tableItems" :key="item.informationId">
            <b-alert show variant="success">
              {{ item.registrationTimeDisplay }}
              <br />
              {{ item.informationDetail }}
            </b-alert>
          </li>
        </ul>
      </div>
      <!-- <b-alert show variant="success">
        {{ message }}
      </b-alert> -->
    </template>
    <template v-slot:footer="{}">
      <!-- はいボタン -->
      <BaseButton
        variant="primary"
        :columnInfo="columnInfos.yes"
        @click="notifyConfirm"
      />
    </template>
  </BaseModal>
</template>

<script>
import { init, afterCloseUpdate } from '@/system/notice/NoticeModal.js'

export default {
  name: 'NoticeModal',

  inheritAttrs: false,

  props: {
    /**
     * 項目定義情報.
     * @type {Object}
     */
    columnInfos: {
      type: Object,
      required: true,
    },

    /**
     * メッセージ.
     * @type {String}
     */
    message: {
      type: String,
      default: '',
    },
  },
  computed: {
    detailInfo() {
      return this.$store.getters['init/getScreens'](
        this.SCREEN_ID.NOTICE_MODAL
      )('detail_info')
    },
  },
  data() {
    return {
      noticeCount: 0,
      /**
       * 作業データリスト.
       * @type {Array}
       */
      tableItems: [],
      obj: this,
    }
  },

  // instead @show-modal="init(obj)"
  // created() {
  //   init(this)
  // },

  methods: {
    init,

    /**
     * モーダルを閉じた後の処理です.
     */
    notifyConfirm() {
      afterCloseUpdate(this)
      this.$bvModal.hide('notice-modal')
      this.$emit('notify-confirm')
    },
  },
}
</script>

<style lang="scss" scoped>
div#messageBox {
  width: 460px;
  height: 300px;
  overflow-y: auto;
}
ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
div {
  padding: 0px;
}
</style>
